import "core-js/modules/es.array.push.js";
// import routes from '../router/routes'

import { isCnIDCard } from '@/api/validator';

// import signature from "@/components/signature";

export default {
  name: 'index',
  // components: { signature },
  data() {
    //身份证校验

    return {
      user: {},
      userinfo: {},
      showxy: false,
      uploadUrl: '/api/api/upload',
      signImgUrl: '',
      showSign: false,
      panelVisible: false,
      panelTitle: '',
      options: {
        penColor: '#000',
        minWidth: 1,
        //控制画笔最小宽度
        maxWidth: 1 //控制画笔最大宽度
      },

      isActive1: true,
      isActive2: false,
      isActive3: false,
      imgSrc: '',
      loading: false,
      sfzok: false,
      form: {
        sfz: '',
        ty: false
      },
      images: [{
        id: 1,
        url: '/assets/1.jpg'
      }, {
        id: 2,
        url: '/assets/2.jpg'
      }, {
        id: 3,
        url: '/assets/3.jpg'
      }, {
        id: 4,
        url: '/assets/4.png'
      }],
      htmls: [],
      html: {
        id: 1,
        title: '用户协议',
        html: '用户协议'
      },
      rules: {
        idCardNumber: [{
          required: true,
          message: '身份证不能为空',
          trigger: 'blur'
        }, {
          validator: isCnIDCard,
          message: '身份证未能通过验证'
        }],
        ty: [{
          required: true,
          message: '身份证不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    getProgress() {
      if (this.$data.userinfo) {
        var date = new Date(this.$data.userinfo.menstruation);
        var diff = new Date().getTime() - date.getTime(); // 时间差的毫秒数
        // 计算出相差天数
        var days = Math.floor(diff / (24 * 3600 * 1000));
        return Math.floor(days / 7) + '周' + (days % 7 > 0 ? '+' : '') + days % 7 + '天';
      } else {
        return '';
      }
    },
    submit() {
      var that = this;
      console.log(this.$data.form.sfz);
      // if (!this.form.ty) {
      //   that.$toast("请先阅读并同意《用户协议》与《隐私政策》");
      //   return;
      // }
      this.$refs.form.validate().then(() => {
        console.log('成功！');
        that.$http.get('/api/qsn/bindingSfz', {
          sfz: this.$data.form.sfz
        }, '服务器正在查询您的信息...').then(result => {
          console.log(result);
          if (result.success) {
            var userstr = JSON.parse(localStorage.getItem('user'));
            userstr.user = result.data.user;
            userstr.studentInfo = result.data.studentInfo;
            userstr.agreement = result.data.agreement;
            if (userstr.agreement) {
              that.$store.commit('setUserInfo', userstr);
              that.$router.push('/sign');
            } else {
              that.$toast('未查询到相关信息，请联系老师！');
            }
          } else {
            that.$toast(result.msg);
            //this.showQYWXCode();
          }
        }).catch(err => {
          console.log(err);
        });
      }).catch(() => {
        console.log('失败');
      });
    },
    afterUpload(url) {
      this.signImgUrl = url;
      this.showSign = false;
      //图片保存上传完毕进行修改个人签名图片路径数据
    },

    closeSign() {
      this.showSign = false;
    },
    showTxt(val) {
      this.html = this.htmls[val];
      this.showxy = true;
    },
    getzqtys() {
      var that = this;
      // this.$http
      //   .get('/api/qsn/getAgreementInfos/1', null, null)
      //   .then((result) => {
      //     console.log(result);
      //     that.htmls.push({ id: 1, title: 'aaa', html: result.data.contents });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      // this.$http
      //   .get('/api/qsn/getAgreementInfos/2', null, null)
      //   .then((result) => {
      //     console.log(result);
      //     that.htmls.push({ id: 2, title: 'bbb', html: result.data.contents });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },

    created() {}
  },
  mounted() {
    console.log('-----------------------------------------');
    this.getzqtys();
    var userstr = localStorage.getItem('user');
    var user = JSON.parse(userstr);
    this.$data.user = user.user;
    this.$data.userinfo = user.userinfo;
    console.log(this.$data.user);
  }
};